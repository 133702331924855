<template>
    <div class="container">
        <div class="row">
            <div class="col s12 left-align">
                <h1>Terms and Conditions TMPR</h1>
              <h2>TMPR, TradeMark Public Register is a service provided by OPM CORPORATION I and the portal CryptoCurrency10.com</h2>
            </div>

            <div class = "col s12 blue-grey darken-3 divide" ></div>
            <div class="col s12 left-align">
              <h5>Welcome to CryptoCurrency10.com</h5>
              <p>
                CryptoCurrency10.com is a website dedicated to presenting Cryptocurrencies users with a great variety of services owned by OPM CORPORATION, I a corporation organized and existing under the laws of Republic of Panama, (“the Company”), since August Twenty-second (22nd) of 2008 with the registered address at Av. Samuel Lewis, Omega Tower, 5th Floor, Office D, Panama City, Republic of Panama with Registration Number 629583.<br>
                Agreeing to the terms contained in this code (“Agreement”) is a legal contract covering the services provided by CryptoCurrency10 (“CryptoCurrency10.com” or “CryptoCurrency10 or “us”, “our”, or “we”) to the user (“you” or “your”). Any use made by the user will be determined by the terms of this Agreement, together with our Company’s Privacy Policy. To ensure that you understand the terms and conditions of the Agreement and Privacy Policy, we advise you to read them carefully before accepting them.
              </p>
              <h5>Acceptance and Possible Changes in Terms</h5>
              <p>In order to create and register an account, acceptance of our Terms of Service and Privacy Policy is necessary. When accepting this Agreement and the use of these services you acknowledge that you are knowingly entering into a legal agreement with CryptoCurrency10 that you will comply with the terms of this Agreement.
                If at any time there are significant changes that affect your rights and obligations as stated in this Agreement, we will take all feasible steps to notify you of these changes before they take effect. In the case you disagree with the changes, you may terminate the Agreement and close your account.
              </p>
              <h5>Registration and User Account</h5>
              <p>
                In order to gain access to this site, to use its services or any of its resources, you may be requested to enter information regarding your registration to login to your user account (“User Account” or “account”). All information you provide must be correct, current, and complete or we may refuse you access to the site or any of its resources; in the case of false or incomplete information, we reserve the right to close or suspend your account.<br>
                You must have attained 18 years of age to be legally able to access our services. Only one account is available per user and access to another person’s account with CryptoCurrency10.com is expressly forbidden. You may use the account only for yourself, and you may neither act as an intermediary or broker for anyone or any entity other than yourself, nor is it permissible to sell, share or put your account or access information at the disposition of any other person or entity.<br>
                You are accountable for the security and for managing all passwords, usernames, two-factor authentication codes and all other account-related information necessary for accessing our services.<br>
                Information provided in your account must be accurate and not ambiguous or deceptive, including but not limited to an impersonal phone number. Using false reputation information for your account, stating a different country of origin or furnishing falsified identification documents is prohibited.
              </p>
              <h5>Company Accounts</h5>
              <p>
                If you wish to open an account with us as a company or other entity, you must enter your account as a company. A company-entered account is considered an individual and only the person who has set up and registered the account may use it. As with personal accounts, other persons or entities may not access Company accounts.<br>
                Company-entered accounts are permitted the following exemptions from our Terms of Service:
              </p>
              <ul>
                <li>Any company is permitted to have multiple user accounts opened at the same time if they are each company-entered and used by a single employee. Each employee may register and use a single account.</li>
                <li>Companies may have active advertisements posted in multiple accounts with, however, the following exception:</li>
                <li>Ads may not be superimposed between accounts. E.I., advertisements using the same limits and price in the same category of means for payment is not permitted in the same country.</li>
              </ul>
              <h5>Identity Verification</h5>
              <p>For CryptoCurrency10 to maintain a secure platform for all of our customers, we may find it necessary to request an identity verification when you access any of our services. If you decide to put in ads, or if an investigation for fraud ensues and we need to confirm ownership of an account, we may request a proof of identity; this may entail providing your full name, in addition to documents and photographs verifying your identity. There may be certain occasions requiring ulterior authentication, such as origin of funds used for both payments made for or received from currency trading on CryptoCurrency10, and purchases or sales of Crypto Currencies using your CryptoCurrency10 account.<br>
                If you lose access to your account, we can more easily restore access to your account if your ID has been authenticated in the above manner; restoring access is very difficult, or even impossible, without this authentication process, even if you know your user-name, because there is no way of recognizing the original owner.
              </p>
              <h5>Limits, Suspension and Closure of Your Account</h5>
              <p>From time to time, it may be necessary place a limit on, suspend or close your account. Limiting (“limit”, “limitation”) your account temporarily or permanently curtails your access to some of our specific services. A suspension (“suspension”, “block”, “ban”) leaves you with a temporary loss of access to most of our services whereas the closure (“closure”, “permanent ban”) permanently takes away access to all our services in addition to permanently ending this Agreement.
                We reserve the right to limit, suspend or close your account and access to our services if:
              </p>
              <ol>
                <li>. We suspect that you have, in any way, violated or gone against any part of these Terms of Service or any laws or regulations pertaining to them, or we suspect that any data or ideas you have entered or shared through our services goes against the Terms of Service of this site and/or pertinent laws or regulations or rights of others;</li>
                <li>We feel that you use our services for illegal or fraudulent activities such as money laundering, financing terrorism or any other financial crimes;</li>
                <li>We believe that any of the information you have furnished during registration, identity confirmation, trades or other services is bogus, incorrect, or deceitful toward us or any user;</li>
                <li>Any law or regulation, or if we receive an official order that requires us to do so;</li>
                <li>you provide material or act in any way that libels, defames, invades privacy, stalks, is obscene, pornographic, racist, abusive, harassing, threatening or is offensive;</li>
                <li>You use our services recklessly, thus interrupting or damaging or impairing the site’s ability to function; or, if you attempt to breach or break any of the site’s security measures; or, if CryptoCurrency10 deems that this action is necessary to protect the security of its services, property or brand, or the safety, property, and rights of its users and/or others;</li>
                <li>You attempt to hack into other users’ accounts or any other element of the service.</li>
                <li>We will warn you of any limitation, suspension, or closure on your account, but it may be that, if there is sufficient reason for doing so (i.e., we feel it is advisable for security reasons), we may limit, suspend, or close your account without forewarning.</li>
                <li>You accept that if we determine to limit, suspend, or close your account, it may be founded on classified motives which are necessary for risk control and security programs, and that we are not required in any case to reveal private elements of our risk control or security programs.</li>
                <li>If we limit, suspend, or close your account, we will return your privileges as quickly as possible once the reason for our action has been eliminated.</li>
              </ol>
              <h5>Allowed Jurisdictions</h5>
              <p>It is possible that you will have to fulfill legal requirements in your country and/or state of residence to use CryptoCurrency10. When accepting these Terms of Service, you state that you have read through your local laws and regulations ad that you are aware of and have complied these requirements.</p>
              <h5>Intellectual Property Rights</h5>
              <p>You recognize and agree that all copyrights, trademarks, and other intellectual property rights on and relative to this site and the services herein are the exclusive property of CryptoCurrency10 and our licensors. We afford you limited license that can be revoked at any time, and which is non-exclusive, non-transferrable and cannot be sublicensed, subject to the terms of this Agreement, to access and make use of our site and services in addition to relative content, information, and data (collectively, the “Content”) only for reasons that are approved and allowed by us on occasion. We absolutely forbid any other use of our Content, and you agree to not to copy, transmit, distribute, sell, license, reverse engineer, modify, publish or engage in sharing or selling, creating works deriving from the Content, or taking advantage of it either partially or in full</p>
              <h5>Disclaimer of Guarantees and Limitation of Responsibility</h5>
              <p>
                We provide this site and its services on an “as is” and “as available” basis for your information and use, without representation or authorization. As far as legally allowed, we offer absolutely no guarantees, direct or implied, regarding the site or services which include, among others, implied guarantee of good quality, functionality, appropriateness for specific operations, non-infringement, compatibility, security, accuracy, condition or totality, or any other implied guarantees that spring from dealing, use or trade.<br>
                CryptoCurrency10 OY or CryptoCurrency10.com has no association or partnership of any kind with Online Payment methods that may appear or are listed or advertised. In addition, services that we offer are not authorized, approved or endorsed by the payment companies listed on the site or their trademark owners. Payment means listed on CryptoCurrency10.com are only for your information.<br>
                No content created by the site’s users, such as messages, feedback, advertisements, etc. are the responsibility of CryptoCurrency10, although it reserves the right to delete or change any of said content without forewarning or liability and according to its judgment.<br>
                It is possible that you will find links to other sites on Internet on CryptoCurrency10; CryptoCurrency10 doesn’t control these sites, which may present topics that some users could find offensive and tasteless. You recognize that CryptoCurrency10 is not responsible for the lawfulness, impropriety, correctness or any other positions of these sites (despite their affiliation or not with CryptoCurrency10).<br>
                CryptoCurrency10 has the right to change or discontinue, temporarily or on a permanent basis, any part of this website and/or software, resources and services found on the site, ad may do so without prior warning and may set up new guidelines and limits for their use.<br>
                As far as the law allows, we will not be held responsible for:
              </p>
              <ol>
                <li>Any financial losses (including loss of income, profits, contracts, business or expected savings);</li>
                <li>Any loss of goodwill or reputation;</li>
                <li>Any special, indirect or significant losses, of any origin and regardless of whether possible losses were within the considerations of either the users or this site on the date in which the losses were incurred. Without limitations, you, the users shall assume the responsibility of any expenses incurred for necessary servicing, repairs or corrections should losses or damages ensue.<br>
                  Nothing in these Terms of Service shall exclude or limit our liability based on willful conduct or gross negligence. If, and to the extent applicable law does not allow the above limitations of warranty and liability, such limitations shall not apply to you.
                  Despite any other provision in these Terms of Service, there will be no limits your rights as a consumer under mandatory provisions of applicable consumer protection legislation.
                </li>
              </ol>
              <h5>Compensation</h5>
              <p>You agree to take on full responsibility (and pay full compensation to CryptoCurrency10) for all damages, losses, costs and expenses – including legal fees – sustained by the site and originating from any infraction of this Agreement by you, the user, and your use of the site’s services, or any other person accessing your account, device or internet access, or because of your infraction of any law or third-party rights.</p>
              <h5>General</h5>
              <p>
                CryptoCurrency10 may transfer or consign its rights and duties described in this Agreement to any party at any time without notifying you, the user; this will have no impact on your rights or the site’s obligations according to this Agreement. However, this right to transfer isn’t extended to the users.<br>
                This Agreement (as occasionally amended) contains the complete agreement and accord between both parties regarding all issues referred to herein and replaces all preceding agreements between the site and the user relative to these matters. No verbal explanations or information exchanged by the two parties (CryptoCurrency10 and the user) may constitute an alteration of the meaning of this Agreement. With the acceptance of this Agreement, you confirm that you accept this written version and none other than is not included here; you also agree that you will not seek redress regarding any misinterpretation that is not a term of this Agreement. However, nothing in this Agreement implies excluding liability for any fraudulent statement or action.
                If any portion of this Agreement is found to be illegal, invalid or cannot be enforced for any reason, that clause shall be cut from this Agreement without affecting the validity or enforceability of any other clause contained in this Agreement.<br>
                If we do not apply any right or clause in this Agreement, it doesn’t mean that we give up our right to do so, unless we provide a written statement confirming this. Unless it is precisely provided for in this Agreement, the use of its remedies by either the site or the user will be without prejudice to any other countermeasure in the Agreement unless allowed by the law.
                You agree that the Agreement and/or any disagreement between you (the user) and us (CryptoCurrency10) shall be decided upon according to the laws of Panama, regardless of their choice of law clauses, excepting application of the 1980 U.N. Convention on Contracts for the International Sale of Goods. Unless it is expressly prohibited and without limitation to any statutory rights for consumers under applicable consumer protection laws, you agree that all disagreements, claims, and proceedings arising out of or relating to the services shall be resolved by the appropriate courts of Panama. However, the site, CryptoCurrency10, reserves the right to resolve legal disagreements in the appropriate court in the jurisdiction of the user’s residence.<br> All claims shall be brought within one (1) year after the claim arises. If either party fails to act on any right provided for in this Agreement, it does not mean that this right has been dismissed. The services are controlled and offered by us from Panama. We do not guarantee that these services can be accessed from all countries. CryptoCurrency10 users must make sure that they can legally access our services from their personal jurisdiction.
              </p>
            </div>


        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    h6{
        font-weight: bold;
    }
    .example{
        max-width: 100%;
        margin-top: 40px;
        margin-bottom: 150px;
    }

    .divide{
        height: 2px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    strong {
      font-weight: 700;
    }

    @media screen and (max-width: 600px){
        .example{
            margin-bottom: 30px;
        }
    }

</style>
